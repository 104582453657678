interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  providers: any[];
  children: JSX.Element;
}

export const Compose = ({ providers, children }: Props) => (
  <>
    {providers.reverse().reduce((acc, curr) => {
      const [Provider, props] = Array.isArray(curr) ? [curr[0], curr[1]] : [curr, {}];

      return <Provider {...props}>{acc}</Provider>;
    }, children)}
  </>
);
