import { NavLink, useLocation } from "react-router-dom";
import GridViewIcon from "@mui/icons-material/GridView";
import ExploreIcon from "@mui/icons-material/Explore";
import TuneIcon from "@mui/icons-material/Tune";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import DevicesIcon from "@mui/icons-material/Devices";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import { useState } from "react";
import Collapse from "@mui/material/Collapse";

import "./sidebar.scss";

export const Sidebar = (): JSX.Element => {
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const activeClassName = ({ isActive }: { isActive: boolean }): string =>
    isActive ? "sidebar__navigation-link sidebar__navigation-link--active" : "sidebar__navigation-link";

  const toggleMenu = (): void => {
    setOpen(!open);
  };

  return (
    <div className="sidebar">
      <div className="sidebar__logo">Nutrition</div>

      <hr className="sidebar__divider" />

      <nav className="sidebar__navigation">
        <ul className="sidebar__navigation-list">
          <li className="sidebar__navigation-list-item">
            <NavLink
              end
              onClick={() => setOpen(false)}
              className={(navData) => activeClassName(navData)}
              to="/dashboard">
              <GridViewIcon className="sidebar__navigation-icon" /> Dashboard
            </NavLink>
          </li>
          <li className="sidebar__navigation-list-item">
            <div
              className={activeClassName({ isActive: location.pathname.includes("system-configuration") })}
              onClick={toggleMenu}>
              <TuneIcon className="sidebar__navigation-icon" /> System configuration
              {open ? (
                <ExpandLessIcon className="sidebar__navigation-icon--expand" />
              ) : (
                <ExpandMoreIcon className="sidebar__navigation-icon--expand" />
              )}
            </div>
            <Collapse
              in={open}
              timeout="auto"
              unmountOnExit>
              <ul className="sidebar__submenu">
                <li className="sidebar__submenu-item">
                  <NavLink
                    end
                    className={(navData) => `${activeClassName(navData)} sidebar__submenu-item-link`}
                    to="/system-configuration/grain-type">
                    Manage Grain Type
                  </NavLink>
                </li>
                <li className="sidebar__submenu-item">
                  <NavLink
                    end
                    className={(navData) => `${activeClassName(navData)} sidebar__submenu-item-link`}
                    to="/system-configuration/nutrition-factors">
                    Manage Nutrition Factors
                  </NavLink>
                </li>
                <li className="sidebar__submenu-item">
                  <NavLink
                    end
                    className={(navData) => `${activeClassName(navData)} sidebar__submenu-item-link`}
                    to="/system-configuration/expected-values">
                    Manage Expected Values
                  </NavLink>
                </li>
                <li className="sidebar__submenu-item">
                  <NavLink
                    end
                    className={(navData) => `${activeClassName(navData)} sidebar__submenu-item-link`}
                    to="/system-configuration/location-management">
                    Location Management
                  </NavLink>
                </li>
              </ul>
            </Collapse>
          </li>
          <li className="sidebar__navigation-list-item">
            <NavLink
              end
              onClick={() => setOpen(false)}
              className={(navData) => activeClassName(navData)}
              to="/actual-readings">
              <ExploreIcon className="sidebar__navigation-icon" /> Actual Readings
            </NavLink>
          </li>
          <li className="sidebar__navigation-list-item">
            <NavLink
              end
              onClick={() => setOpen(false)}
              className={(navData) => activeClassName(navData)}
              to="/manage-devices">
              <DevicesIcon className="sidebar__navigation-icon" /> Manage Devices
            </NavLink>
          </li>
          <li className="sidebar__navigation-list-item">
            <NavLink
              end
              onClick={() => setOpen(false)}
              className={(navData) => activeClassName(navData)}
              to="/trends">
              <ShowChartIcon className="sidebar__navigation-icon" /> Trends
            </NavLink>
          </li>
        </ul>
      </nav>
    </div>
  );
};
