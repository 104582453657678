import "./layout.scss";
import { Sidebar } from "./sidebar/sidebar";

interface Props {
  children: JSX.Element | null;
}

export const Layout = ({ children }: Props): JSX.Element => {
  return (
    <div className="page">
      <div className="page__sidebar">
        <Sidebar />
      </div>
      <div
        id="page__content"
        className="page__content">
        {children}
      </div>
    </div>
  );
};
