import { clearStorage } from "./../hooks/use-local-storage.hook";
import axios, { AxiosRequestHeaders } from "axios";
import { environment } from "../environments/environment";
import { isAuthenticated } from "../hooks/use-local-storage.hook";
import storage from "./storage";

const http = axios.create({
  headers: {
    "Content-type": "application/json",
  },
});

const getHeaders = (): AxiosRequestHeaders => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let headers: any = {};

  if (isAuthenticated()) {
    const accessToken = storage.get("accessToken");

    headers = { Authorization: `Bearer ${accessToken}` };
  }

  return headers;
};

http.interceptors.request.use(
  (config) => {
    config.headers = getHeaders();
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

http.interceptors.response.use(
  (response) => {
    return response;
  },
  async (err) => {
    const originalConfig = err.config;

    if (!originalConfig.url.includes("/admin/auth/login") && err.response) {
      if (err.response.status === 403 && !originalConfig._retry) {
        if (originalConfig.url.includes("/admin/auth/token")) {
          clearStorage();
          location.reload();
        } else {
          originalConfig._retry = true;
        }

        try {
          const rs = await http.post(`${environment.apiBaseUrl}/admin/auth/token`, {
            refreshToken: storage.get("refreshToken"),
          });

          const { accessToken } = rs.data;

          localStorage.setItem("refreshToken", accessToken);

          return http(originalConfig);
        } catch (_error) {
          return Promise.reject(_error);
        }
      }
    }

    return Promise.reject(err);
  },
);

export const addAccessToken = () => {
  const token: string = storage.get("accessToken");
  http.interceptors.request.use(async (config) => {
    config.headers["authorization"] = `Bearer ${token}`;
    return config;
  });
};

export default http;
