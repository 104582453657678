import { createTheme } from "@mui/material/styles";
import "@mui/material/styles/createPalette";

declare module "@mui/material/styles" {
  interface Palette {
    lightGrey: Palette["primary"];
    darkGrey: Palette["primary"];
  }

  interface PaletteOptions {
    lightGrey: PaletteOptions["primary"];
    darkGrey: PaletteOptions["primary"];
  }
}

declare module "@mui/material/Checkbox" {
  interface CheckboxPropsColorOverrides {
    darkGrey: true;
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    darkGrey: true;
  }
}

export const theme = createTheme({
  palette: {
    primary: { main: "#0A1E2F", contrastText: "#FFF", dark: "#0A1E2F" },
    secondary: { main: "#64BBA9", contrastText: "#FFF", light: "#fff" },
    error: { main: "#F76659", contrastText: "#FFF" },
    warning: { main: "#FFAF02", contrastText: "#FFF" },
    info: { main: "#608DFF", contrastText: "#FFF" },
    success: { main: "#5DC637", contrastText: "#FFF" },
    lightGrey: { main: "#D0D5DD", contrastText: "#FFF" },
    darkGrey: { main: "#455468", contrastText: "#FFF" },
  },
  typography: {
    htmlFontSize: 16,
    fontFamily: "'Cera Pro', sans-serif",
    h1: {
      fontSize: 96,
    },
    h2: {
      fontSize: 64,
    },
    h3: {
      fontSize: 32,
    },
  },
});
