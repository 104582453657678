// This file can be replaced during build by using the `fileReplacements` array.
// When building for production, this file is replaced with `environment.prod.ts`.

export const environment = {
  production: false,
  apiBaseUrl: "https://api-stage.feedmixrr.com/api",
  snackbarAnchorOrigin: {
    vertical: "top",
    horizontal: "right",
  },
  snackBarAutoHideDuration: 3000,
};
