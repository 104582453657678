import { ComponentType, useMemo } from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../../providers/auth-provider";
import { Layout } from "../layout/layout";
import { SuspenseWithFallback } from "../suspense-with-fallback/suspense-with-fallback";

interface Props {
  component: ComponentType<object>;
}

export const AuthenticationGuard = ({ component }: Props): JSX.Element => {
  const { isAuthenticated } = useAuth();
  const Component = useMemo(() => (isAuthenticated ? component : null), [isAuthenticated, component]);

  return (
    <SuspenseWithFallback>
      <Layout>{isAuthenticated ? Component && <Component /> : <Navigate to="/login" />}</Layout>
    </SuspenseWithFallback>
  );
};
