import { environment } from "../environments/environment";
import { AuthToken } from "../interfaces";
import http from "../utils/http";

const AUTH_ENDPOINT = `${environment.apiBaseUrl}/admin/auth`;

const login = async (email: string, password: string): Promise<AuthToken> => {
  return http.post<AuthToken>(`${AUTH_ENDPOINT}/login`, { email, password }).then((response) => response.data);
};

const refreshToken = async (refreshToken: string): Promise<AuthToken> => {
  return http.post<AuthToken>(`${AUTH_ENDPOINT}/token`, { refreshToken }).then((response) => response.data);
};

export const Auth = {
  login,
  refreshToken,
};
