import React from "react";
import { Routes, Route } from "react-router";
import { Navigate } from "react-router-dom";
import { AuthenticationGuard } from "./components/private-route/private-route";
import { SuspenseWithFallback } from "./components/suspense-with-fallback/suspense-with-fallback";
import {
  LoginPage,
  DashboardPage,
  GrainTypePage,
  NutritionFactorsPage,
  ActualReadingsPage,
  ManageDevicesPage,
  LocationManagementPage,
  ManageExpectedValuesPage,
  TrendsPage,
} from "./routes";

function App() {
  return (
    <Routes>
      <Route
        path="/login"
        element={
          <SuspenseWithFallback>
            <LoginPage />
          </SuspenseWithFallback>
        }
      />

      {/* Protected routes */}

      <Route
        path="/dashboard"
        element={<AuthenticationGuard component={DashboardPage} />}
      />

      <Route
        path="/system-configuration/grain-type"
        element={<AuthenticationGuard component={GrainTypePage} />}
      />

      <Route
        path="/system-configuration/nutrition-factors"
        element={<AuthenticationGuard component={NutritionFactorsPage} />}
      />

      <Route
        path="/system-configuration/location-management"
        element={<AuthenticationGuard component={LocationManagementPage} />}
      />

      <Route
        path="/system-configuration/expected-values"
        element={<AuthenticationGuard component={ManageExpectedValuesPage} />}
      />

      <Route
        path="/actual-readings"
        element={<AuthenticationGuard component={ActualReadingsPage} />}
      />

      <Route
        path="/manage-devices"
        element={<AuthenticationGuard component={ManageDevicesPage} />}
      />

      <Route
        path="/trends"
        element={<AuthenticationGuard component={TrendsPage} />}
      />

      {/* Default route */}

      <Route
        path="*"
        element={
          <Navigate
            to="/login"
            replace
          />
        }
      />
    </Routes>
  );
}

export default App;
