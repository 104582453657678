import { lazy } from "react";

export const LoginPage = lazy(() => import("../pages/login/login"));
export const DashboardPage = lazy(() => import("../pages/dashboard/dashboard"));
export const GrainTypePage = lazy(() => import("../pages/grain-type/grain-type"));
export const NutritionFactorsPage = lazy(() => import("../pages/nutrition-factors/nutrition-factors"));
export const ActualReadingsPage = lazy(() => import("../pages/actual-readings/actual-readings"));
export const ManageDevicesPage = lazy(() => import("../pages/manage-devices/manage-devices"));
export const LocationManagementPage = lazy(() => import("../pages/location-management/location-management"));
export const ManageExpectedValuesPage = lazy(() => import("../pages/manage-expected-values/manage-expected-values"));
export const TrendsPage = lazy(() => import("../pages/trends/trends"));
