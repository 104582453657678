import { createContext, useContext, useState } from "react";
import { clearStorage, useLocalStorage } from "../hooks/use-local-storage.hook";
import { AuthToken, WithChildren } from "../interfaces";
import { Auth } from "../services/auth.service";
import storage from "../utils/storage";

interface AuthContextProps {
  token: string | null;
  isAuthenticated: boolean;
  auth: (email: string, password: string) => Promise<AuthToken>;
  logout: () => void;
}

const AuthContext = createContext<AuthContextProps>({
  token: null,
  isAuthenticated: false,
  auth: () => new Promise<AuthToken>(() => undefined),
  logout: () => false,
});

export const useAuth = () => useContext(AuthContext);

export const AuthProvider: React.FC = ({ children }: WithChildren) => {
  const token: string = storage.get("accessToken");
  const [, setToken] = useLocalStorage("accessToken", "");
  const [, setRefreshToken] = useLocalStorage("refreshToken", "");
  const [isAuthenticated, setIsAuthenticated] = useState(!!token);

  const auth = async (email: string, password: string): Promise<AuthToken> => {
    const response = await Auth.login(email, password);

    try {
      setToken(response.accessToken);
      setRefreshToken(response.refreshToken);
      setIsAuthenticated(true);
    } catch (err) {
      setIsAuthenticated(false);
    }

    return response;
  };

  const logout = (): void => {
    clearStorage();
    setIsAuthenticated(false);
  };

  return <AuthContext.Provider value={{ token, isAuthenticated, auth, logout }}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
